<template>
  <v-sheet
    height="100%"
    color="transparent"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <core-subheading>
          Writing
        </core-subheading>
        <v-list class="transparent">
          <v-list-item class="mb-3">
            <v-list-item-action>
              <v-icon color="primary">
                mdi-pencil
              </v-icon>
            </v-list-item-action>
            <v-list-item-title class="mr-2">
              <h4>
                The Columbian
              </h4>
              My most recent
              <a
                target="_new"
                href="https://docs.google.com/document/d/1EfJXBiFF8xFyk9jHTtjy0DDiROFLB7yGXR_IctdN3h8/edit?usp=sharing"
              >article
              </a>
              for the school newspaper
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="mb-3">
            <v-list-item-action>
              <v-icon color="primary">
                mdi-pencil
              </v-icon>
            </v-list-item-action>
            <v-list-item-title class="mr-2">
              <h4>
                ECEA Essay Contest - 3rd Place
              </h4>
              An
              <a
                target="_new"
                href="https://docs.google.com/document/d/1anxt1WIojP0ilHqmHUYsJjY595jfgTzd2I6A4iaIUsc/edit?usp=sharing"
              >
                essay
              </a>
              I wrote for an essay contest pertaining to my school career
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="mb-3">
            <v-list-item-action>
              <v-icon color="primary">
                mdi-pencil
              </v-icon>
            </v-list-item-action>
            <v-list-item-title class="mr-2">
              <h4>
                Stockton University Creative Writing Contest (Poetry)- Honorable Mention
              </h4>
              A
              <a
                target="_new"
                href="https://docs.google.com/document/d/1gLbGy6JMzvaJRPa4Vn6mp8ukXzpqMBO4NrDwOOBJcrY/edit?usp=sharing"
              >poem
              </a>
              I wrote
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="mb-3">
            <v-list-item-action>
              <v-icon color="primary">
                mdi-pencil
              </v-icon>
            </v-list-item-action>
            <v-list-item-title class="mr-2">
              <h4>
                Stockton University Creative Writing Contest (Flash Fiction) - Honorable Mention
              </h4>
              A
              <a
                target="_new"
                href="https://docs.google.com/document/d/1MQgMp9oq5UgDL_LuE7Im3URxDYip2dshWsSI4RoEF9w/edit?usp=sharing"
              >flash fiction piece
              </a>
              I wrote
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="mb-3">
            <v-list-item-action>
              <v-icon color="primary">
                mdi-web
              </v-icon>
            </v-list-item-action>
            <v-list-item-title class="mr-2">
              <h4>
                The GuildScript Magazine 2021 - Co-Editor and featured author
              </h4>
              <a
                target="_new"
                href="http://theguildscript.com"
              >http://theguildscript.com
              </a>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="hidden-sm-and-down"
      >
        <v-img
          :src="require(`@/assets/projects/katetrumpet.png`)"
          height="350"
          width="500"
          contain
          class="mx-auto"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="5"
      >
        <core-subheading>
          Research
        </core-subheading>
        <v-list class="transparent">
          <v-list-item>
            <v-list-item-action>
              <v-icon color="primary">
                mdi-clipboard-check-outline
              </v-icon>
            </v-list-item-action>
            <v-list-item-title class="mr-2">
              <h4>
                Delaware Valley University: Determining Pasture Yield- Research Assistant
              </h4>
              <a
                target="_new"
                href="https://docs.google.com/presentation/d/1nOWx9IQrclX8V_HMrtxl06W261jOe17C/edit?usp=sharing&ouid=118443419523044473218&rtpof=true&sd=true"
              >Determining Pasture Yield
              </a>
              <br>
              <a
                target="_new"
                href="https://docs.google.com/document/d/1N-2QWmHVbYBXDRVQ6T40dfXi2HItnZxG/edit?usp=sharing&ouid=118443419523044473218&rtpof=true&sd=true"
              >Pasture Yield at Hameau
              </a>
              <br>
              <a
                target="_new"
                href="https://docs.google.com/document/d/1s10L_n6zgXjR9neRDzSrWRVCjFXa-_NL/edit?usp=sharing&ouid=118443419523044473218&rtpof=true&sd=true"
              >Pasture Yield 2
              </a>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col
        cols="12"
        md="7"
        class="hidden-sm-and-down"
      >
        <v-img
          :src="require(`@/assets/projects/kateatfarm.jpg`)"
          height="350"
          width="500"
          contain
          class="mx-auto"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
  export default {
    props: {
      value: {
        type: Object,
        default: () => ({
          name: '',
          category: '',
          src: undefined,
        }),
      },
    },
  }
</script>
<style>
.v-list-item__title {
  text-overflow: unset !important;
  white-space: normal !important;
}
</style>
